'use client';
import React from 'react';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import MyLink from '@/components/shared/myLink/MyLink';

const index = () => {
	return (
		<div id='FAQ' className='container my-20'>
			<p className='titleHeading'>الأسئلة الشائعة</p>
			<p className='text-25 text-textColor font-[500] max-w-[963px] mx-auto text-center mb-10'>
				أكثر الأسئلة الشائعة لدى الطلاب والإجابة عليها.
			</p>
			<Accordion
				type='single'
				collapsible
				className='flex flex-col gap-5'
				defaultValue='item-1'>
				<AccordionItem
					value='item-1'
					className='bg-[#252F39] py-[15px] px-[20px] rounded-[20px]'>
					<AccordionTrigger className='text-20 text-white font-[500]'>
						كيف يتم التواصل مع الدعم الفني للمنصة؟
					</AccordionTrigger>
					<AccordionContent className='text-16 text-textColor font-[500]'>
						يمكنك التواصل مع الدعم الفني عبر:
						<br />
						واتس آب:{' '}
						<MyLink
							href='https://wa.me/+201501394069'
							className='text-primaryColor'
							target='_blank'>
							اضغط هنا
						</MyLink>
						<br />
						صفحة الفيسبوك:{' '}
						<MyLink
							href='https://www.facebook.com/xhubeg/'
							className='text-primaryColor'
							target='_blank'>
							تواصل معنا
						</MyLink>
						<br />
						إنستجرام:{' '}
						<MyLink
							href='https://www.instagram.com/x.hubeg'
							className='text-primaryColor'
							target='_blank'>
							من هنا
						</MyLink>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
};

export default index;
